<template>
	<div class="main">
		<div class="header">
			<img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
			<img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
			<span class="header-txt">智惠妇儿</span>
			<!-- 关闭 刷新 -->
			<div class="idx-middlesx-box3">
				<div class="idx-middle-1">
					<img src="@/assets/image/zhfe/return.png" @click="getback" />
					<img src="@/assets/image/zhfe/icon/sx.png" alt="" @click="refresh" />
				</div>
			</div>
		</div>
		<div class="time-box">
			<Date></Date>
		</div>
		<div class="title">“两类”女童一对一帮扶</div>
		<div class="container">
			<div class="awards-filter-wrap">
				<el-form :inline="true" :model="queryParams" class="demo-form-inline">
					<el-form-item label="所属年份：">
						<el-select v-model="queryParams.helpYear" clearable placeholder="所属年份">
							<el-option v-for="item in yearOptions" :key="item" :label="item" :value="item"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属季度：">
						<el-select v-model="queryParams.helpQuarter" clearable placeholder="所属季度">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索</el-button>
						<el-button type="primary" @click="onReset">重置</el-button>
						<el-button type="primary" @click="handleEdit">+新增</el-button>
						<el-button type="primary" class="other" @click="onImport">导入</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table-wrap">
				<el-table
					:data="lingbanList"
					stripe
					:style="{
						width: '100%',
						maxHeight: `${windowHeight > 1200 ? 68 : windowHeight > 1025 ? 56 : windowHeight > 800 ? 52 : 42}vh`,
						overflowY: ' auto',
					}"
					header-align="center"
					align="center"
					:cell-style="{ background: 'revert' }"
				>
					<el-table-column type="index" label="序号" width="80" align="center"> </el-table-column>
					<el-table-column prop="helpArea" label="区域" align="center"> </el-table-column>
					<el-table-column prop="helpUserCount" label="人数" align="center"> </el-table-column>
					<el-table-column prop="helpCount" label="帮扶次数" align="center"> </el-table-column>
					<el-table-column prop="helpYear" label="所属年份" align="center"> </el-table-column>
					<el-table-column prop="helpQuarter" label="所属季度" align="center"> </el-table-column>
					<el-table-column label="操作" align="center" width="120">
						<template slot-scope="scope">
							<el-button type="text" class="delete" @click="handleDelete(scope.row)">删除</el-button>
							｜
							<el-button type="text" class="edit" @click="handleEdit(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination-wrap">
				<el-pagination
					background
					@size-change="handleSizeChange"
					layout="total,sizes,prev, pager, next"
					:page-sizes="[10, 20, 50, 100]"
					@current-change="handleCurrentChange"
					:total="total"
					:current-page="queryParams.pageNum"
					:page-size="queryParams.pageSize"
					:prev-text="'上一页'"
					:next-text="'下一页'"
					popper-class="pagination-select"
				>
				</el-pagination>
			</div>
		</div>
		<el-dialog
			:visible.sync="editVisible"
			title=""
			class="import-dialog"
			:append-to-body="true"
			:close-on-click-modal="false"
		>
			<div class="dialog-title">{{ dialogTitle }}</div>
			<el-form
				ref="form"
				:inline="true"
				label-position="right"
				label-width="120px"
				:model="form"
				:rules="rules"
				class="demo-form-inline dialog-form-inline"
			>
				<el-form-item label="所属社区" prop="helpArea">
					<el-select v-model="form.helpArea" clearable placeholder="所属区域">
						<el-option v-for="item in area" :key="item" :label="item" :value="item"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属年份" prop="helpYear">
					<el-date-picker v-model="form.helpYear" format="yyyy" value-format="yyyy" type="year" placeholder="所属年份">
					</el-date-picker>
					<!-- <el-select v-model="form.helpYear" clearable placeholder="">
						<el-option v-for="item in yearOptions" :key="item" :label="item" :value="item"> </el-option>
					</el-select> -->
				</el-form-item>
				<el-form-item label="所属季度" prop="helpQuarter">
					<el-select v-model="form.helpQuarter" clearable placeholder="所属季度">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="人数" prop="helpUserCount">
					<el-input v-model.number="form.helpUserCount" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="帮扶次数" prop="helpCount">
					<el-input v-model.number="form.helpCount" placeholder=""></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="editVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleConfirm">确定{{ dialogTitle }}</el-button>
			</span>
			<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="editVisible = false" alt="" />
		</el-dialog>
		<el-dialog
			title=""
			class="import-dialog loading-dialog"
			:visible.sync="import_dialog"
			:append-to-body="true"
			:close-on-click-modal="false"
		>
			<div
				class="dialog-box"
				v-loading="uploadLoading"
				element-loading-text="导入中..."
				element-loading-spinner="el-icon-loading"
				element-loading-background="rgba(0, 0, 0, 0.8)"
			>
				<div class="dialog-title">“两类”女童一对一帮扶导入</div>
				<div class="dialog-upload">
					<el-upload
						class="upload-demo"
						drag
						action=""
						:accept="accept"
						:http-request="uploadHttp"
						:file-list="fileList"
						:before-upload="handleBeforeUpload"
						:on-error="handleUploadError"
						:before-remove="handleRemove"
						:limit="1"
					>
						<!-- <i class="el-icon-upload"></i> -->
						<div class="el-upload__text">
							<img class="upload-icon" src="@/assets/image/upload.png" alt="" />
							<p>点击或将文件拖拽到这里上传</p>
							<p>支持拓展名：.xls .xlsx</p>
						</div>
						<div class="el-upload__tip" slot="tip" @click="getFileName">下载模版</div>
					</el-upload>
				</div>
				<div class="dialog-operate-wrap" style="margin-top: 20px">
					<el-button type="primary" @click="import_dialog = false">取消</el-button>
					<el-button type="primary" @click="handleExportConfirm">提交</el-button>
				</div>
				<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="import_dialog = false" alt="" />
			</div>
		</el-dialog>
		<el-dialog
			title=""
			class="delete-dialog"
			:visible.sync="delete_dialog"
			width="780px"
			:append-to-body="true"
			:close-on-click-modal="false"
		>
			<div class="dialog-title">系统提示</div>
			<div class="dialog-content">是否确认删除“{{ dataDetail && dataDetail.helpArea }}”数据项？</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="delete_dialog = false">取 消</el-button>
				<el-button type="primary" @click="handleDeleteConfirm">确 定</el-button>
			</span>
			<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="delete_dialog = false" alt="" />
		</el-dialog>
	</div>
</template>
<script>
import Date from '@/views/commonComponents/date.vue';
import {
	getzhferecordadminlist,
	addzhferecord,
	zhferecordimportData,
	getRecordYear,
	deletezhferecord,
	savezhferecord,
	zhferecordimportTemplate,
} from '@/api/seedlings';
import { Validate, CatchError } from '@/utils/decorator';
import { download, getFileName, importData } from '@/api/file';
import { download as downloadFile, resetPage } from '@/utils/index.js';
export default {
	components: { Date },
	data() {
		return {
			queryParams: {
				pageNum: 1,
				pageSize: 20,
				helpYear: null,
				helpQuarter: null,
			},
			lingbanList: [],
			total: 0,
			yearOptions: [],
			options: [
				{
					value: 1,
					label: '第一季度',
				},
				{
					value: 2,
					label: '第二季度',
				},
				{
					value: 3,
					label: '第三季度',
				},
				{
					value: 4,
					label: '第四季度',
				},
			],
			form: {
				helpArea: null,
				helpUserCount: null,
				helpCount: null,
				helpYear: null,
				helpQuarter: null,
			},
			rules: {
				helpArea: [{ required: true, message: '请选择所属社区', trigger: 'change' }],
				helpUserCount: [
					{ required: true, message: '请输入人数', trigger: 'change' },
					{ type: 'number', message: '人数必须为数字值' },
				],
				helpCount: [
					{ required: true, message: '请输入帮扶次数', trigger: 'change' },
					{ type: 'number', message: '帮扶次数必须为数字值' },
				],
				helpYear: [{ required: true, message: '请选择所属年份', trigger: 'change' }],
				helpQuarter: [{ required: true, message: '请选择所属季度', trigger: 'change' }],
			},
			area: ['温塘', '余屋', '主山', '牛山', '同沙', '岗贝', '东泰', '星城', '花园新村'],
			editVisible: false,
			dialogTitle: '',
			import_dialog: false,
			accept: '.xls,.xlsx',
			fileList: [],
			loading: false,
			uploadLoading: false,
			dataDetail: null,
			delete_dialog: false,
			windowHeight: 0,
		};
	},
	methods: {
		handleDeleteConfirm() {
			deletezhferecord(this.dataDetail.recordId).then((res) => {
				if (res.code == 200) {
					this.queryParams.pageNum = resetPage(this.queryParams.pageNum, this.queryParams.pageSize, this.total, 1);
					this.getzhferecordadminlist();
					this.delete_dialog = false;
					// this.$router.go(0); //刷新当前页面
				}
			});
		},
		async getFileName() {
			const { msg } = await zhferecordimportTemplate();
			const resource = await download({ fileName: msg });
			const link = URL.createObjectURL(resource);
			downloadFile(link, '“两类”女童一对一帮扶导入模版.xlsx');
		},
		uploadHttp({ file }) {
			this.fileList.push(file);
			this.loading.close();
		},
		handleUploadError() {
			this.$message({
				type: 'error',
				message: '上传失败',
			});
			this.loading.close();
		},
		handleBeforeUpload(file) {
			this.loading = this.$loading({
				lock: true,
				text: '上传中',
				background: 'rgba(0, 0, 0, 0.7)',
			});
			const accept = this.accept.split(',');
			let type = file.name.split('.');
			type = '.' + type[type.length - 1];
			const isType = accept.some((item) => {
				return type === item;
			});
			if (!isType) {
				this.$message.error(`上传文件只能是 ${this.accept}格式`);
				this.loading.close();
			}
			const isLt1GB = file.size / 1024 / 1024 / 1024 < 1;
			if (!isLt1GB) {
				this.$message.error('上传文件的大小不能超过 1GB!');
				this.loading.close();
			}
			return isType && isLt1GB;
		},
		handleRemove(file, fileList) {
			this.fileList = fileList;
		},
		onImport() {
			if (!this.existSystem()) {
				return false;
			}
			this.fileList = [];
			this.import_dialog = true;
			this.uploadLoading = false;
		},
		handleExportConfirm() {
			if (this.fileList.length === 0) {
				return this.$message.error('请选择上传文件!');
			}
			this.uploadLoading = true;
			Promise.all(
				this.fileList.map((file) => {
					let formData = new FormData();
					formData.append('file', file);
					return zhferecordimportData(formData);
				})
			)
				.then(async (res) => {
					const { msg } = res[0];
					if (msg === '操作成功') {
						this.$message({ type: 'success', message: '导入成功' });
						this.uploadLoading = false;
						this.$router.go(0); //刷新当前页面
					} else {
						this.$message.info('导入失败,正在下载导入失败数据...');
						this.uploadLoading = false;
						const resource = await download({ fileName: msg });
						const link = URL.createObjectURL(resource);
						downloadFile(link, '“两类”女童一对一帮扶导入失败数据.xlsx');
					}
				})
				.catch(() => {
					this.uploadLoading = false;
				});
		},
		refresh() {
			this.$router.go(0); //刷新当前页面
		},
		getback() {
			this.$router.go(-1); //刷新当前页面
		},
		onSubmit() {
			if (!this.existSystem()) {
				return false;
			}
			this.queryParams.pageNum = 1;
			this.getzhferecordadminlist();
		},

		onReset() {
			if (!this.existSystem()) {
				return false;
			}
			this.queryParams = {
				pageNum: 1,
				pageSize: 20,
				helpYear: null,
				helpQuarter: null,
			};
			this.getzhferecordadminlist();
		},
		handleEdit(row) {
			if (!this.existSystem()) {
				return false;
			}
			const { recordId } = row;
			this.form = { ...row };
			this.dialogTitle = recordId ? '修改' : '新增';
			this.editVisible = true;
			this.$nextTick(() => {
				this.$refs['form'].clearValidate();
			});
		},
		async handleDelete(row) {
			if (!this.existSystem()) {
				return false;
			}
			const { recordId, helpArea } = row;
			this.dataDetail = row;
			this.delete_dialog = true;
			return;
			try {
				await this.$confirm(`此操作将永久删除“${helpArea}”这条记录, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				});
				await deletezhferecord(recordId);
				this.getzhferecordadminlist();
			} catch {
				this.$message({
					type: 'info',
					message: '已取消删除',
				});
			}
		},
		handleSizeChange(e) {
			this.queryParams.pageSize = e;
			this.getzhferecordadminlist();
		},
		handleCurrentChange(e) {
			this.queryParams.pageNum = e;
			this.getzhferecordadminlist();
		},
		@Validate('form')
		@CatchError()
		async handleConfirm() {
			this.form.recordId ? await savezhferecord(this.form) : await addzhferecord(this.form);
			this.getzhferecordadminlist();
			this.editVisible = false;
		},
		async getzhferecordadminlist() {
			const { rows, total } = await getzhferecordadminlist(this.queryParams);
			this.lingbanList = rows;
			this.total = total;
		},
	},
	async mounted() {
		this.getzhferecordadminlist();
		const { data: year } = await getRecordYear();
		this.yearOptions = year;
		this.windowHeight = document.body.clientHeight;
	},
};
</script>
<style lang="scss" scoped>
@import '../zhfeHome/index.scss';
.title {
	position: fixed;
	top: 7vh;
	z-index: 99;
	height: 5.7vh;
	text-align: center;
	line-height: 5.7vh;
	font-size: 2.4vh;
	font-family: hy;
	margin-top: 1vh;
	text-align: left;
	padding-left: 3.5vw;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #e6598e;
}
.main {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	padding-bottom: 2vh;
	.container {
		flex: 1;
		min-width: 980px;
		max-width: 98%;
		width: 98%;
		margin: 0 auto;
		border: 2px solid rgba(255, 212, 228, 1);
		margin-top: 20px;
		padding-bottom: 20px;
		.awards-filter-wrap {
			width: 100%;
			border-bottom: 1px solid rgba(255, 212, 228, 1);
			padding: 20px;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			::v-deep .el-form {
				.el-form-item {
					padding-right: 45px;
					.el-form-item__label {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ce739c;
					}
				}

				.el-input__inner,
				.el-textarea__inner {
					width: initial;
				}
			}
		}
		.table-wrap {
			padding: 20px 20px 0;
		}
		::v-deep .el-table {
			margin: 0;
			background-color: transparent;
			-ms-overflow-style: none;
			overflow: -moz-scrollbars-none;
			&::-webkit-scrollbar {
				width: 0 !important;
			}
			&:before {
				background-color: transparent;
			}
			.el-table__header-wrapper {
				/* height: 35px; */
				tr {
					background-color: transparent;
					.el-table__cell {
						background-color: rgba(180, 75, 122, 0.7);
						padding: 0px;
						height: 35px;

						.cell {
							font-size: 15px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}
			.el-table__row {
				background-color: rgba(180, 75, 122, 0.2);
			}
			.el-table__row--striped {
				background-color: rgba(180, 75, 122, 0.4);
				td.el-table__cell {
					background: transparent;
				}
			}
			th.el-table__cell.is-leaf {
				border-bottom: none;
			}
			td.el-table__cell {
				border-bottom: none;
			}
			.cell {
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #fff;
			}
		}
		.delete {
			color: #73ff87;
		}
		.edit {
			color: #379aff;
			margin: 0;
		}
	}
}
.el-button--primary {
	/* width: 68px; */
	height: 38px;
	background: rgba(255, 212, 228, 0.4);
	border: 1px solid #ffd4e4;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #eea8c4;
	border-radius: 0px;
	&.other {
		clip-path: polygon(
			0 0,
			100% 0,
			100% 0,
			100% calc(100% - 12px),
			calc(100% - 12px) 100%,
			0 calc(100% + 12px),
			0 calc(100% + 12px),
			-2px -2px
		);
		background: linear-gradient(-45deg, #ffd4e4 8px, rgba(255, 212, 228, 0.4) 0) bottom right,
			linear-gradient(45deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) bottom left,
			linear-gradient(135deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) top left,
			linear-gradient(-135deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) top right;
		background-size: 50% 50%;
		background-repeat: no-repeat;
	}
}
::v-deep .el-dialog {
	min-height: 491.08px;
	.el-dialog__body {
		padding: 0 50px 50px;
	}
	.el-upload--picture-card {
		background-color: transparent;
		border: 1px dashed #ffd4e4;
		.el-icon-plus {
			color: #ffd4e4;
		}
	}
	.el-dialog__footer {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: 50px;
	}
	.close-dialog {
		position: absolute;
		top: -16px;
		right: -16px;
		cursor: pointer;
		z-index: 2001;
	}
	.dialog-title {
		margin: 0 auto;
		min-width: 240px;
		width: max-content;
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 62px;
		background: #cd80a2;
		border-radius: 0px 0px 14px 14px;
		background-size: 16px 16px;
		border: 2px solid #fcd2e2;
		margin-bottom: 30px;
		font-size: 22px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}
	.el-button--primary {
		width: 180px;
		height: 44px;
		background: #002953;
		border: 1px solid #ffd4e4;
		border-radius: 2px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffd4e4;
	}
	.el-button + .el-button {
		margin-left: 78px;
	}
}
.pagination-wrap {
	display: flex;
	justify-content: flex-end;
	padding-right: 18px;
	padding-top: 20px;
	.el-pagination__total {
		font-size: 12px;
		font-family: SimSun;
		font-weight: 400;
		color: #cceeff;
		line-height: 18px;
	}
}
::v-deep .demo-form-inline {
	.el-form-item {
		margin-bottom: 20px;
		align-items: center;
		justify-content: center;
		padding-right: 70px;

		.el-form-item__label {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffd4e3;
		}
		.el-date-editor.el-input {
			width: 296px;
		}
		.el-input__inner,
		.el-textarea__inner {
			width: 296px;
			height: 38px;
			background: linear-gradient(270deg, rgba(205, 128, 162, 0), rgba(216, 149, 178, 0.17), rgba(205, 128, 162, 0));
			border: 1px solid #ffd4e4;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffb3cf;
			border-radius: 0;
			&::placeholder {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffb3cf;
			}
		}
	}
	&.dialog-form-inline {
		.el-form-item {
			width: 100%;
			display: flex;
			margin-bottom: 20px;

			.el-form-item__content {
				/* flex: 1; */
			}
		}
	}
}
::v-deep.import-dialog {
	.el-dialog__body {
		padding: 0;
	}
	.el-dialog__header {
		padding: 0;
		.el-dialog__headerbtn {
			top: 30px;
			right: 22px;
			padding: 10px;
			.el-dialog__close {
				color: transparent;
			}
		}
	}
	.dialog-box {
		.close-dialog {
			position: absolute;
			top: -16px;
			right: -18px;
			cursor: pointer;
			z-index: 2001;
		}
		.dialog-content {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffd4e4;
			margin: 88px 20px 0 0;
			text-align: center;
		}
		.dialog-operate-wrap {
			margin: 76px 20px 0 0;
			display: flex;
			justify-content: center;
			align-items: center;
			.dialog-operate {
				width: 180px;
				height: 44px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #002953;
				border: 1px solid #ffd4e4;
				border-radius: 2px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffd4e4;
				cursor: pointer;
				&.reset {
					margin-right: 47px;
				}
			}
		}
	}
	&.import-dialog {
		.dialog-box {
			height: 491.08px;
			position: relative;
		}
		.dialog-upload {
			margin: 20px auto;
			display: flex;
			justify-content: center;
			width: 544px;
			.upload-demo {
				width: 100%;

				.el-upload {
					width: 100%;
					height: 146px;
					background: rgba(1, 20, 40, 0.45);
					border-radius: 2px;
					border: 1px solid #ffd4e4;
					.el-upload-dragger {
						width: 100%;
						height: 100%;
						background: transparent;
						border: none;
						.el-upload__text {
							display: flex;
							justify-content: center;
							align-items: center;
							flex-direction: column;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffd4e4;

							.upload-icon {
								width: 26px;
								display: block;
								margin: 26px 0 0;
							}
							p {
								margin-top: 13px;
							}
						}
					}
				}
				.el-upload__tip {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffd4e4;
					cursor: pointer;
					margin-top: 21px;
				}
				.el-upload-list {
					min-height: 40px;
					max-height: 100px;
					overflow-y: auto;
					.el-upload-list__item {
						&:hover {
							background-color: transparent;
						}
						.el-upload-list__item-name {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffd4e4;
							.el-icon-document {
								display: none;
							}
						}
						.el-icon-close {
							&::before {
								color: #fff;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}
::v-deep.delete-dialog {
	.el-dialog {
		min-height: 150px;
	}

	.dialog-content {
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffd4e4;
	}
}
</style>

<style>
.el-table--enable-row-hover .el-table__body tr:hover > td {
	background-color: rgba(180, 75, 122, 0.7) !important;
}
.pagination-wrap .el-pagination__total {
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #cceeff;
	line-height: 18px;
}
.pagination-wrap .el-input__inner {
	background: rgba(9, 21, 45, 0.4);
	border: 1px solid #b44b7a;
	color: #ffffff;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
}
.pagination-wrap .btn-prev,
.pagination-wrap .btn-next {
	width: 80px;
	background-color: rgba(180, 75, 122, 0.2) !important;
	border: 1px solid #b44b7a;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #fcd2e2 !important;
	line-height: 14px;
}
.pagination-wrap .btn-prev:disabled,
.pagination-wrap .btn-next:disabled {
	width: 80px;
	background-color: rgba(180, 75, 122, 0.4);
	border: 1px solid #b44b7a;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #fcd2e2 !important;
	line-height: 14px;
}
.pagination-wrap .number {
	background-color: rgba(180, 75, 122, 0.4) !important;
	border: 1px solid #b44b7a !important;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	border-radius: 0px;
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #fcd2e2 !important;
}
.pagination-wrap .active {
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #ffffff !important;
	background: url('../../assets/image/zhfe/pagination_bg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	border: none !important;
}
</style>